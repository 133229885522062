<template>
  <div class="page">
    <div class="formcontainer">
      <div class="formbox tutorial">
        <h2 class="blue">Başvuru Nasıl Yapılır?</h2>
        <h4 class="textcenter">
          Başvuru adımlarında dikkat edilmesi gerekenler;
        </h4>

        <div class="formcontainer staticpage">
          <div class="tutline">
            <img src="@/assets/img/nasil/nasil_hs.svg" alt />
            <div>
              <div class="tuttitle">
                Başvuru süreci sadece
                <span class> <tool-tip-hs></tool-tip-hs>lar </span> içindir.
              </div>
              <p>
                İleti Yönetim Sistemi hakkında bilgi almak isteyen alıcılar
                <router-link to="/vatandaslar">
                  <b>Vatandaşlar </b> </router-link
                >sayfasını ziyaret edebilir.
              </p>
            </div>
          </div>

          <div class="tutline">
            <img src="@/assets/img/nasil/nasil_mersis.svg" alt />
            <div>
              <div class="tuttitle">
                Başvuruyu sadece MERSİS kaydı olan ve bu kayda göre yetkili
                görünen kişiler yapabilir.
              </div>
              <p>
                MERSİS’te yer alan bilgilerinizin doğru olduğundan emin olunuz.
                Güncel olmayan bilgilerinizi ancak
                <a
                  target="_blank"
                  class="blue"
                  href="https://mersis.gtb.gov.tr/"
                >
                  <b>MERSİS üzerinde</b>
                </a>
                güncelleyebilirsiniz.
              </p>
              <div class="info">
                Mersis'te kayıtlı değilseniz İleti Yönetim Sistemi'ne nasıl
                başvuracağınız hakkında detaylı bilgi almak için
                <a @click="SET_MODAL('ModalNoMersis')" class="pointer blue"
                  >tıklayınız</a
                >.
              </div>
            </div>
          </div>

          <div class="tutline">
            <img src="@/assets/img/nasil/nasil_belge.svg" alt />
            <div>
              <div class="tuttitle">
                Başvuru öncesinde Marka Tescil Belgelerinizi PDF formatında
                hazırlayınız.
              </div>
              <p>
                Ticari unvanınız dışında farklı isimlerle de ticari elektronik
                ileti gönderimi yapıyorsanız, marka tescil belgelerinin sisteme
                yüklenmesi zorunludur.
              </p>
            </div>
          </div>

          <!-- <div class="tutline half formline">
            <img src="@/assets/img/nasil/nasil_eposta.svg" alt />
            <div>
              <div class="tuttitle">Lütfen kurumsal e-posta adresinizle başvurunuz.</div>
            </div>
          </div> -->

          <div class="basvuru formline mt">
            <h3>e-Devlet'le Başvuru</h3>
            <div class="basvurucontent">
              <p>
                e-Devlet'le başvuru daha hızlı şekilde yürütülebilmektedir.
                MERSİS kaydınız mevcutsa e-Devlet şifrenizle giriş yapınız.
                <br /><br />
                MERSİS kaydınız bulunmuyorsa
                <a
                  class="blue desclink"
                  @click="SET_MODAL('ModalNoMersis')"
                  >şuradaki</a
                >
                <!-- <a
                  class="blue desclink"
                  @click="SET_MODAL('ModalTaahhutnameNoMersis')"
                  >şuradaki</a
                > -->
                adımları takip ederek başvurunuzu manuel olarak
                tamamlayabilirsiniz.
              </p>

              <div class="wide30">
                <div class="btn red">
                  <!-- <div class="full" @click="EDEVLET_CLICK">
                    <a>e-Devlet girişi</a>
                  </div>-->
                  <router-link
                    class="full"
                    to="/hizmet-saglayici/basvuru/yeni-giris/?secim=e-devlet"
                    >e-Devlet girişi</router-link
                  >
                </div>

                <div class="desclink" @click="SET_MODAL('ModalTaahhutname')">
                  <a class="pointer">T.C. vatandaşı değil misiniz?</a>
                </div>
              </div>
            </div>
            <!-- <div class="btn half center third mt"> -->
          </div>

          <div class="basvuru formline mt">
            <h3>E-İmzayla Başvuru</h3>
            <div class="basvurucontent">
              <p class>
                E-imzayla başvurunuzu daha kolay şekilde tamamlayabilmek için
                lütfen aşağıdaki bilgileri inceleyiniz.
              </p>

              <div class="btn wide30">
                <router-link
                  class="center"
                  to="/hizmet-saglayici/basvuru/yeni-giris"
                  >E-imzayla başvuruya başla</router-link
                >
              </div>
            </div>

            <div class="gizlegoster" @click="e_imza_detay = !e_imza_detay">
              {{ e_imza_detay ? "Detayları gizle ⮝" : "Detaylı bilgi ⮟" }}
            </div>

            <div v-if="e_imza_detay" class="tutline">
              <img src="@/assets/img/nasil/nasil_eimzatoken.svg" alt />
              <div>
                <div class="tuttitle">
                  Yetkili kişilerin elektronik imza token'larının yanlarında
                  bulunması gerekir.
                </div>
                <p>
                  <a
                    href="/pdf/iys_kullanim_taahhutnamesi_ornek.pdf"
                    target="_blank"
                  >
                    <b>Temel Hizmetler Kullanım Taahhütnamesi</b> </a
                  >'ni imzalamalısınız. Müştereken temsil yetkisi bulunuyorsa en
                  az bir yetkilinin daha, ilk yetkili tarafından imzalanmış
                  dokümanı imzalaması gerekmektedir.
                </p>
                <p>
                  Taahhütnamenin elektronik olarak imzalanması için
                  <a class="blue" @click="SET_MODAL('ModalVideo')">
                    <b>Elektronik imzayla PDF doküman nasıl imzalanır?</b>
                  </a>
                  videomuzdan yardım alabilirsiniz.
                </p>
                <p>
                  Eğer sorun yaşıyorsanız
                  <a
                    class="blue"
                    href=" https://www.e-inovasyon.com/download.html"
                    >eSignBox®</a
                  >
                  veya
                  <a
                    class="blue"
                    href="https://yazilim.kamusm.gov.tr/?q=/node/5"
                    >İmzager</a
                  >
                  yazılımlarını da kullanabilirsiniz. Bu yazılımlarla atılan
                  imzalar PDF belgesi üzerinde imza panelinde
                  görüntülenmektedir.
                </p>
              </div>
            </div>

            <div v-if="e_imza_detay" class="tutline mt">
              <img src="@/assets/img/nasil/nasil_acrobat.svg" alt />
              <div>
                <p class>
                  Taahhütnameyi elektronik olarak imzalayabilmek için
                  bilgisayarınızda
                  <a
                    class="blue"
                    href="https://get.adobe.com/reader/"
                    target="_blank"
                  >
                    <b>Adobe Acrobat Reader</b> </a
                  >'ın kurulu olması gerekmektedir.
                </p>
              </div>
            </div>
          </div>

          <div class="basvuru formline mt">
            <h3>Mevcut Başvuru</h3>
            <div class="basvurucontent">
              <p class>
                Daha önce başladığınız bir başvuruya devam etmek ya da
                tamamladığınız başvurunuzun durumunu sorgulamak için sağdaki
                bağlantıyı kullanınız.
              </p>

              <div class="btn wide30 second">
                <router-link
                  class="center"
                  to="/hizmet-saglayici/basvuru/devam-giris"
                  >Mevcut Başvuruya Devam Et</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="staticcolumn">
      <div>
        <!-- <h3>Mevzuat</h3>
        <div class="ucebir">
          <h4>E-Ticaret Kanunu</h4>
          <p>6563 sayılı Elektronik Ticaretin Düzenlenmesi Hakkında Kanun</p>
          <div class="btn second">
            <router-link to="/iys/kanun">Görüntüle</router-link>
          </div>
        </div>

        <div class="ucebir">
          <h4>15 Temmuz 2015 Tarihli Yönetmelik</h4>
          <p>Ti̇cari̇ İleti̇şi̇m ve Ti̇cari̇ Elektroni̇k İleti̇ler Hakkında Yönetmeli̇k</p>
          <div class="btn second">
            <router-link to="/iys/yonetmelik">Görüntüle</router-link>
          </div>
        </div>

        <div class="ucebir">
          <h4>04 Ocak 2020 Tarihli Yönetmelik</h4>
          <p>
            Ti̇cari̇ İleti̇şi̇m ve Ti̇cari̇ Elektroni̇k İleti̇ler Hakkında Yönetmeli̇kte
            Deği̇şi̇kli̇k Yapılmasına Dai̇r Yönetmeli̇k
          </p>
          <div class="btn second">
            <router-link to="/iys/yonetmelik-degisiklik">Görüntüle</router-link>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import Keycloak from "keycloak-js";
import { mapMutations } from "vuex";

// import initOptions from "@/../keycloakConfig";
// var keycloak = Keycloak(initOptions);

export default {
  data() {
    return {
      e_imza_detay: false,
    };
  },

  // mounted() {this.SET_MODAL('ModalTaahhutname')},

  created() {
    if (this.$keycloak) {
      this.$env.CONSOLE.warn("LOGOUT URL:", this.$keycloak.createLogoutUrl());
      this.$keycloak.logoutFn();
    } else {
      this.$env.CONSOLE.warn("ALREADY LOGGED OUT.");
    }
  },

  methods: {
    ...mapMutations("app", ["SET_MODAL"]),
  },
};
</script>

<style scoped>
.gizlegoster {
  color: #4da0df;
  cursor: pointer;
}
.mt {
  margin-top: 0 !important;
}

.wide30 {
  min-width: 30%;
}

.basvuru {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.basvuru .tutline {
  width: unset;
}

.basvurucontent {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.basvurucontent p {
  padding: 0 25px 0 0;
  margin-bottom: 0;
}

.flex {
  display: flex;
  justify-content: space-between;
}

.desclink {
  color: #4da0df !important;
  width: 100%;
  text-align: center;
}

.red {
  background: #d33535;
}

.red:hover {
  background: #ff3e3e;
}

.input_fix_murat {
  pointer-events: none;
  background-color: #ebf6ff;
  border: #ffffff;
  font-weight: 600;
}
</style>
